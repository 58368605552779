import React, { useState } from 'react';
import styles from './tenmaxReconciliation.module.scss';
import i18n from 'i18n';
import moment from 'moment';
import { DateRangePickerField } from 'components/form/field/DateRangePickerField';
import { CustomField } from 'components/form/field/CustomField';
import { Button } from 'react-bootstrap';
import { DefaultReportManager } from 'core/report/ReportManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/LoadingIndicator';

const defaultReportManager = new DefaultReportManager();

export const TenmaxReconciliation: React.FC = () => {

  const [from, setFrom] = useState<string>(moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'));
  const [to, setTo] = useState<string>(moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD'));
  const { loading, callAPIs } = useCallAPI();

  const setUpDayRange = (from, to) => {
    if (from) {
      setFrom(moment(from).startOf('day').format('YYYY-MM-DD'));
    }
    if (to) {
      setTo(moment(to).endOf('day').format('YYYY-MM-DD'));
    }
  };

  const downloadReport = () => {
    callAPIs([() => defaultReportManager.downloadTenmaxReconciliationReport(from, to)]);
  };

  return (
    <div className={styles.container}>
      {loading && <LoadingIndicator/>}
      <div className={styles.titleArea}>
        <div className={styles.title}>
          {i18n.t<string>('report.titles.tenmaxReconciliation')}
        </div>
      </div>
      <div className={styles.contentArea}>
        <DateRangePickerField
          label={i18n.t<string>('report.labels.reportGran')}
          startDate={from}
          endDate={to}
          maxDate={moment().endOf('day').subtract(1, 'day').format('YYYY-MM-DD')}
          format={'YYYY-MM-DD'}
          onChange={setUpDayRange}
          showTimePicker={false}
        />
        <CustomField formGroupClassName={styles.reportSearchBtnContainer} label='' name='reportDownloadButton'>
          <Button variant='primary' onClick={downloadReport} size='sm'>
            {i18n.t<string>('report.labels.download')}
          </Button>
        </CustomField>
      </div>
    </div>
  );
};
