import React, { useCallback, useMemo } from 'react';
import { connect, FormikContextType } from 'formik';

import i18next from 'i18n';
import { SelectOptions } from 'components/commonType';
import { selectOptionsAddI18n } from 'utils/I18nUtils';
import styles from './agencyForm.module.scss';
import { FormikField } from 'components/form/field/FormikField';
import { DefaultAgencyManager } from 'core';

type AgencyBasicFormProps = {
  timeZoneOptions: SelectOptions[];
  languageOptions: SelectOptions[];
  currencyOptions: SelectOptions[];
  priorityOptions: SelectOptions[];
  partnershipModeOptions: SelectOptions[];
  isNew: boolean;
};

const agencyManager = new DefaultAgencyManager();

const AgencyBasicForm: React.FC<AgencyBasicFormProps & { formik: FormikContextType<any> }> = ({
  timeZoneOptions,
  priorityOptions,
  languageOptions,
  currencyOptions,
  partnershipModeOptions,
  isNew,
  formik
}) => {

  const setFieldValue = formik.setFieldValue;

  const labelLower = (options: SelectOptions) => {
    return {
      label: options.label.toLowerCase(),
      value: options.value,
      options: options.options
    };
  };

  const timeZoneDom = useMemo(() =>
    timeZoneOptions
      .map(labelLower)
      .map(options => selectOptionsAddI18n(options, ['common.timeZone.'])),
    [timeZoneOptions]
  );
  const languageDom = useMemo(() =>
    languageOptions
      .map(labelLower)
      .map(options => selectOptionsAddI18n(options, ['common.language.'])),
    [languageOptions]
  );
  const currencyDom = useMemo(() =>
    currencyOptions
      .map(labelLower)
      .map(options => selectOptionsAddI18n(options, ['common.currency.'])),
    [currencyOptions]
  );
  const partnershipModeDom = useMemo(() => partnershipModeOptions,[partnershipModeOptions]);

  const onCurrencyChange = useCallback((newCurrency) => {
    setFieldValue('targetBudgetMinimum', agencyManager.getRTBDefaultMinBudgetPerDay(newCurrency));
    setFieldValue('campaignBudgetMinimum', agencyManager.getRTBDefaultMinBudget(newCurrency));
  }, [setFieldValue]);

  const onSelfBuyChange = useCallback((newSelfBuy) => {
    if (!newSelfBuy) {
      setFieldValue('noLimitSelfBuyStatus', false);
    }
  }, [setFieldValue]);

  return (
    <div className={styles.agencyFrom}>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.information')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Input
            label={i18next.t<string>('agency.companyName')}
            name='companyName'
          />
          <FormikField.Select
            label={i18next.t<string>('agency.language')}
            simpleValue
            name='language'
            options={languageDom}
          />
          <FormikField.Select
            label={i18next.t<string>('agency.currency')}
            simpleValue
            disabled={!isNew}
            name='currency'
            options={currencyDom}
            onChange={onCurrencyChange}
          />
          <FormikField.Select
            label={i18next.t<string>('agency.timeZone')}
            simpleValue
            disabled={!isNew}
            name='timeZone'
            options={timeZoneDom}
          />
          <FormikField.Select
            label={i18next.t<string>('agency.priority')}
            simpleValue
            name='priority'
            options={priorityOptions}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.selfBuy')}
            disabled={!isNew}
            name='selfBuy'
            onChange={onSelfBuyChange}
          />
          <FormikField.Tags
            label={i18next.t<string>('agency.limitPublisherStr')}
            name='limitPublisher'
            placeholder={i18next.t<string>('agencyForm.placeholders.tags')}
          />
          <FormikField.Select
            label={i18next.t<string>('agency.partnershipMode')}
            simpleValue
            name='partnershipModeId'
            options={partnershipModeDom}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.noLimitSelfBuyStatus')}
            disabled={!isNew || !formik.values.selfBuy}
            name='noLimitSelfBuyStatus'
          />
          <FormikField.TextArea
            label={i18next.t<string>('agency.comComment')}
            name='comComment'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.form.titles.rtbDeliverySetting')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.InputGroup
            label={i18next.t<string>('agency.targetBudgetMinimum')}
            name='targetBudgetMinimum'
            type='number'
            min={1}
            prefix={formik.values.currency ? formik.values.currency : currencyOptions[0].value}
          />
          <FormikField.InputGroup
            label={i18next.t<string>('agency.campaignBudgetMinimum')}
            name='campaignBudgetMinimum'
            type='number'
            min={1}
            prefix={formik.values.currency ? formik.values.currency : currencyOptions[0].value}
          />
        </div>
      </fieldset>
    </div>
  );
};

export default connect<AgencyBasicFormProps>(AgencyBasicForm);
