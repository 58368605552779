import React from 'react';
import { FormikField } from 'components/form/field/FormikField';
import { Formik, Form } from 'formik';
import { L1ObjectFormData } from './L1ObjectFormModel';
import i18n from 'i18n';
import styles from './l1ObjectForm.module.scss';
import { Link, Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/LoadingIndicator';
import NavigationPrompt from 'components/NavigationPrompt/NavigationPrompt';
import _ from 'lodash';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { Trans } from 'react-i18next';
import { getFieldErrors } from 'utils/FormikUtils';
import { RTBCampaignGroupForm } from './RTBCampaignGroupForm/RTBCampaignGroupForm';

export const L1ObjectForm: React.FC<{ useModel: any }> = (props) => {

  const modelData: L1ObjectFormData = props.useModel();
  if (!modelData.initL1Object) {
    return <LoadingIndicator />;
  }

  return (
    <div className={styles.l1ObjectForm}>
      <NavigationPrompt when={!modelData.redirectData}/>
      {modelData.loading && <LoadingIndicator />}
      {modelData.redirectData && <Redirect to={modelData.redirectData} />}
      <div className={styles.titleArea}>
        <TitleWithBreadcrumbs
          title={modelData.title}
          routes={modelData.breadcrumbs}
        />
      </div>
      <Formik
        initialValues={modelData.initL1Object}
        enableReinitialize
        onSubmit={modelData.save}
        validate={modelData.validate}
        validateOnBlur={false}
      >
        {({ values, errors, touched, submitCount, setValues }) => {
          const getBudgetTips = () => {
            if (getFieldErrors({ errors, touched, submitCount }, 'budget')) {
              return;
            }
            if (!modelData.showRemainBudget(values)) {
              return;
            }
            const currentBudget = _.defaultTo(values.budget, 0).toString();
            return (
              <Trans i18nKey='campaign.descriptions.remainBudget'>
                Remain budget <span className='text-dark'><>{{ currency: modelData.order.currency }}{{ remainBudget: modelData.totalBudget - (+currentBudget) }}</></span>
              </Trans>
            );
          };

          const channelFormatter = value => {
            return i18n.t<string>(`l1Object.labels.channel_${value.toLowerCase()}`);
          };
          const renderForm = (channel: L1ObjectChannel | any) => {
            switch (channel) {
              case L1ObjectChannel.RTB:
              default:
                return (
                  <RTBCampaignGroupForm
                    currency={modelData.order.currency}
                    getBudgetTips={getBudgetTips}
                  />
                );
            }
          };

          return (
            <>
              <Form>
                <div className={styles.formContent}>
                  <FormikField.Input
                    label={i18n.t<string>('l1Object.labels.name')}
                    name='name'
                  />
                  {modelData.formType === 'create' ?
                    <FormikField.Select
                      label={i18n.t<string>('l1Object.labels.channel')}
                      name='channel'
                      simpleValue
                      options={modelData.l1ObjectTypeOptions}
                      className={styles.selectField}
                      onChange={_.partial(modelData.onChannelChange, setValues)}
                    /> :
                    <FormikField.Label
                      label={i18n.t<string>('l1Object.labels.channel')}
                      name='channel'
                      formatter={channelFormatter}
                    />
                  }
                  {renderForm(values.channel)}
                </div>
                <div className={styles.formButtons}>
                  <button
                    type='submit'
                    className='btn btn-primary btn-sm'
                  >
                    {i18n.t<string>('common.buttons.submit')}
                  </button>
                  <Link
                    to={`${modelData.cancelTargetPath}`}
                    className='btn btn-secondary btn-sm'
                  >
                    {i18n.t<string>('common.buttons.cancel')}
                  </Link>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
