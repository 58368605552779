import { Actor, PermissionAware, PermissionContext, PMaxCore, RoleNames } from 'core';
import { AgenciesHomeModel, DefaultAgenciesHomeModel } from './AgenciesHome/AgenciesHomeModel';
import { AgencyDetailModel, DefaultAgencyDetailModel } from './AgencyDetail';
import { CreateAgencyFormModel, AgencyFormModel, EditAgencyFormModel } from './AgencyForm';
import { hasFuncs, hasRoles } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import _ from 'lodash';

export interface AgenciesModel {

  readonly operator: Actor | null;
  readonly operatorAgencyPath: string | null;

  readonly homeModel: AgenciesHomeModel;

  readonly canEditAgency: boolean;
  readonly canCreateAgency: boolean;
  readonly canViewAllAgencies: boolean;
  readonly isAgencyView: boolean;
  readonly isSystemViewOnly: boolean;
  canViewAgency (): boolean;
  getAgencyDetailModel (agencyId: number): AgencyDetailModel;
  getCreateAgencyFormModel (): AgencyFormModel;
  getEditAgencyFormModel (agencyId: number): AgencyFormModel;
  onUnmount (): void;
}

export type AgenciesProps = {

  readonly model: AgenciesModel;
};

export class DefaultAgenciesModel implements AgenciesModel {
  operator: Actor | null;
  homeModel: AgenciesHomeModel;
  agencyDetailModel?: AgencyDetailModel;
  createAgencyFormModel?: CreateAgencyFormModel;
  editAgencyFormModel?: EditAgencyFormModel;

  editPermission: PermissionAware;
  viewPermission: PermissionAware;
  createPermission: PermissionAware;
  viewAllPermission: PermissionAware;

  context: PermissionContext;

  constructor (private core: PMaxCore) {
    this.operator = core.authenticationManager.actor;
    this.context = {
      actor: core.authenticationManager.actor,
      agencyAddon: core.addonFeatureManager.addonFeature
    };
    this.homeModel = new DefaultAgenciesHomeModel(core.agencyManager);

    this.createPermission = hasFuncs(Permission.AGENCY_CREATE);
    this.editPermission = hasRoles(RoleNames.sysAdmin);
    this.viewAllPermission = hasFuncs(Permission.AGENCY_LIST_READ);
    this.viewPermission = hasFuncs(Permission.AGENCY_READ);
  }

  get operatorAgencyPath (): string | null {
    return this.operator && this.operator.agencyId ? `/agencies/${this.operator.agencyId}` : null;
  }

  canViewAgency (): boolean {
    return this.viewPermission.visible(this.context);
  }

  get canEditAgency (): boolean {
    return this.editPermission.visible(this.context);
  }

  get isAgencyView (): boolean {
    return this.isAgencyAdmin;
  }

  get isSystemViewOnly (): boolean {
    return this.isSystemAdmin;
  }

  get canCreateAgency (): boolean {
    return this.createPermission.visible(this.context);
  }

  get canViewAllAgencies (): boolean {
    return this.viewAllPermission.visible(this.context);
  }

  get isSystemAdmin (): boolean {
    if (this.operator) {
      return this.operator.roleName === RoleNames.sysAdmin;
    }
    return false;
  }

  get isAgencyAdmin (): boolean {
    if (this.operator) {
      return this.operator.roleName === RoleNames.agencyAdmin;
    }
    return false;
  }

  getAgencyDetailModel (agencyId: number) {
    if (this.agencyDetailModel &&
      agencyId === this.agencyDetailModel.agencyId &&
      (!this.core.authenticationManager.account || this.core.authenticationManager.account.id === this.agencyDetailModel.currentAccountId)
    ) {
      return this.agencyDetailModel;
    }
    this.agencyDetailModel = new DefaultAgencyDetailModel(agencyId, this.core.authenticationManager.actor, _.get(this.core.authenticationManager.account, 'id', 0));
    return this.agencyDetailModel;
  }

  getCreateAgencyFormModel () {
    if (this.createAgencyFormModel) {
      return this.createAgencyFormModel;
    }
    this.createAgencyFormModel = new CreateAgencyFormModel();
    return this.createAgencyFormModel;
  }

  getEditAgencyFormModel (agencyId: number) {
    if (this.editAgencyFormModel && agencyId === this.editAgencyFormModel.agencyId) {
      return this.editAgencyFormModel;
    }
    this.editAgencyFormModel = new EditAgencyFormModel(agencyId,
      this.core.accountManager.updateLocaleMeta.bind(this.core.accountManager, this.core.authenticationManager.actor)
    );
    return this.editAgencyFormModel;
  }

  onUnmount (): void {
    this.agencyDetailModel = undefined;
    this.createAgencyFormModel = undefined;
    this.editAgencyFormModel = undefined;
  }
}
