import { EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { Product, SuggestKeywords } from 'core/product/Product';
import { useCallAPI } from 'hooks/useCallAPI';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './defaultSearchKeywordsComponent.module.scss';
import i18n from 'i18n';
import { Form } from 'react-bootstrap';
import Tags from 'components/Tags/Tags';
import { SelectOptions } from 'components/commonType';
import classnames from 'classnames/bind';
import { compact, flatten, flow, get, partial, trim, uniq } from 'lodash';
import { LoadingIndicator } from 'components/LoadingIndicator';
import closeSmallIcon from 'assets/close-small.svg';
import addIcon from 'assets/add.svg';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import formatters from './listFormatters';

const cx = classnames.bind(styles);

export type SearchKeywordsComponentProps = {
  limitationModel: EditLimitationModel;
  products: Product[];
  adRequestSourceManager?: AdRequestSourceManager;
};

export enum SuggestKeywordsListColumns {
  SUGGEST_KEYWORD = 'suggestKeyword',
  BID_PRICE = 'bidPrice',
  KEYWORD_SEARCH_VOLUME = 'keywordSearchVolume',
  LAST_7_DAY_GROWTH_RATE = 'last7DayGrowthRate',
  LAST_30_DAY_GROWTH_RATE = 'last30DayGrowthRate',
  KEYWORD_AD_COMPETITION = 'keywordAdCompetition'
}

const defaultAdRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager();

export const SearchKeywordsComponent: React.FC<SearchKeywordsComponentProps> = ({
  limitationModel,
  products,
  adRequestSourceManager = defaultAdRequestSourceManager
}) => {

  const keywordInputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<any>(limitationModel.state.errors);
  const includeTA = get(limitationModel.limitationValue, 'include', []);
  const searchKeywordsTA = includeTA.find(ta => ta.type === 'searchKeywords');
  const searchKeywords = searchKeywordsTA ? searchKeywordsTA.value.map(keyword => keyword.value) : [];
  const [inited, setInited] = useState<boolean>(searchKeywords.length > 0 || get(errors, 'include.searchKeywords') !== undefined);
  const [keywords, setKeywords] = useState<string[]>(searchKeywords);
  const [trendingKeywords, setTrendingKeywords] = useState<string[]>([]);
  const [productsHotKeywords, setProductsHotKeywords] = useState<SuggestKeywords[]>([]);
  const { callAPIs, loading } = useCallAPI();

  const uniqKeywords = useCallback((list: SuggestKeywords[]) => {
    const uniqMap: {[key: string]: SuggestKeywords} = {};
    list.forEach(suggestKeyword => {
      const { suggestKeyword: keyword, bidPrice } = suggestKeyword;
      const existSuggestKeyword = uniqMap[keyword];
      if (!existSuggestKeyword || (existSuggestKeyword && +(existSuggestKeyword.bidPrice) < +bidPrice)) {
        uniqMap[keyword] = suggestKeyword;
      }
    });
    return [...Object.values(uniqMap)];
  }, []);

  const fetchTrendingKeywords = useCallback(async () => {
    callAPIs([
      () => (adRequestSourceManager.getTrendingKeywords())
    ], (trendingKeywords: string[]) => {
      const getTrendingKeywords = flow([compact, uniq]);
      setTrendingKeywords(getTrendingKeywords(trendingKeywords));
    }, (error: any) => {
      console.error('fetchTrendingKeywords error', error);
      setTrendingKeywords([]);
    });
  }, [callAPIs, adRequestSourceManager]);

  const fetchProductHotKeywords = useCallback(async () => {
    callAPIs([
      () => adRequestSourceManager.getProductHotKeywords(products.map(product => product.productId))
    ], (productsHotKeywords: SuggestKeywords[]) => {
      const pickFirst200 = (list: SuggestKeywords[]) => list.slice(0, 200);
      const getSuggestKeywords = flow([compact, flatten, uniqKeywords, pickFirst200]);
      setProductsHotKeywords(getSuggestKeywords(productsHotKeywords));
    }, (error: any) => {
      console.error('fetchProductHotKeywords error', error);
      setProductsHotKeywords([]);
    });
  }, [products, adRequestSourceManager, uniqKeywords, callAPIs]);

  const tagsValue = useMemo(() => {
    return keywords.map(keyword => ({ value: keyword, label: keyword }));
  }, [keywords]);

  const firstMount = useRef(true);

  useEffect(() => {
    // only do when dependencies change
    if (firstMount.current) {
      return;
    }
    if (!inited) {
      const pickFirst30 = (list: SuggestKeywords[]) => list.slice(0, 30);
      const getSuggestKeywords = flow([uniqKeywords, pickFirst30]);
      const keywords = getSuggestKeywords(productsHotKeywords).map(keyword => keyword.suggestKeyword);
      setInited(true);
      setKeywords(keywords);
    }
  }, [inited, productsHotKeywords, uniqKeywords]);

  useEffect(() => {
    // only do when dependencies change
    if (firstMount.current || !inited) {
      return;
    }
    limitationModel.setLimitation('include', 'searchKeywords', tagsValue);
  }, [inited, limitationModel, tagsValue]);

  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
      return;
    }
  }, []);

  useEffect(() => {
    fetchTrendingKeywords();
  }, [fetchTrendingKeywords]);

  useEffect(() => {
    fetchProductHotKeywords();
  }, [fetchProductHotKeywords]);

  const addKeyword = useCallback((searchKeywords: string[]): void => {
    setKeywords(keywords => uniq([...keywords, ...searchKeywords]));
  }, []);

  const handleAddSearchString = useCallback((): void => {
    const inputRef = keywordInputRef.current;
    if (!inputRef) {
      return;
    }
    addKeyword(compact(inputRef.value.split(',').map(trim)));
    inputRef.value = '';
  }, [addKeyword]);

  const handleRemoveAllKeywords = useCallback((): void => {
    setKeywords([]);
  }, []);

  const handleOnTagsChange = useCallback((value) => {
    setKeywords(value.map((option: SelectOptions) => option.value));
  }, []);

  const handleOnClickCol = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target instanceof HTMLElement && e.target.className !== 'object' && e.target.className !== 'react-tagsinput-tag') {
      keywordInputRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    const handler = limitationModel.event.add(model => {
      setErrors(model.state.errors);
    });
    return () => {
      limitationModel.event.remove(handler);
    };
  }, [limitationModel]);

  const renderTrendingKeywordsList = useMemo(() => {
    const filteredTrendingKeywords: string[] = trendingKeywords.filter(keyword => !keywords.includes(keyword));
    return (
      <div className={styles.trendingKeywordsListArea}>
        <div className={styles.keywordsListArea}>
          <div className={styles.descript}>
            {i18n.t<string>('limitation.labels.trendingKeywordsDes')}
          </div>
          <div className={styles.keywordsList}>
            {filteredTrendingKeywords.map((keyword, index) => (
              <div key={index} className={styles.capsule}>
                <span>{keyword}</span>
                <img
                  src={addIcon}
                  alt='addTrendingKeyword'
                  onClick={partial(addKeyword, [keyword])}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }, [trendingKeywords, keywords, addKeyword]);

  const renderSuggestKeywordsList = useMemo(() => {
    const filteredProductsHotKeywords: SuggestKeywords[] = productsHotKeywords.filter(keywordData => !keywords.includes(keywordData.suggestKeyword));
    const filteredKeywords: string[] = filteredProductsHotKeywords.map(keywordData => keywordData.suggestKeyword);
    const onClickAll = () => addKeyword(filteredKeywords);
    const columnDefinition = (columnName, sortable: boolean = false, customLabel?: string): ColumnDefinition => ({
      ...sortableColumn(columnName, customLabel ? customLabel : `suggestKeywordsList.labels.${columnName}`, sortable),
      classes: () => styles[columnName],
      headerClasses : () => styles[columnName]
    });
    const keywordColumn = renderColumn({
      ...columnDefinition(SuggestKeywordsListColumns.SUGGEST_KEYWORD),
      formatExtraData: {
        onClick: (searchKeyword: string) => addKeyword([searchKeyword])
      }
    },
    formatters.nameFormatter,
    partial(
      formatters.nameHeaderFormatter,
      filteredKeywords.length > 0,
      onClickAll
    ));
    const columns = compact([
      keywordColumn,
      renderColumn(columnDefinition(SuggestKeywordsListColumns.BID_PRICE), formatters.bidPriceFormatter),
      renderColumn(columnDefinition(SuggestKeywordsListColumns.KEYWORD_SEARCH_VOLUME), formatters.keywordSearchVolumeFormatter),
      renderColumn(columnDefinition(SuggestKeywordsListColumns.LAST_7_DAY_GROWTH_RATE), formatters.growthRateFormatter),
      renderColumn(columnDefinition(SuggestKeywordsListColumns.LAST_30_DAY_GROWTH_RATE), formatters.growthRateFormatter),
      renderColumn(columnDefinition(SuggestKeywordsListColumns.KEYWORD_AD_COMPETITION), formatters.keywordAdCompetitionFormatter)
    ]);
    return (
      <div className={styles.suggestKeywordsListArea}>
        {loading && <LoadingIndicator />}
        <div className={styles.titleArea}>
          <div className={styles.title}>
            {i18n.t<string>('limitation.labels.suggestKeywordsTitle')}
          </div>
          <div className={styles.subTitle}>
            {i18n.t<string>('limitation.labels.suggestKeywordsSubTitle')}
          </div>
        </div>
        <div className={styles.contentArea}>
          <StickableBootstrapTable
            stickFirstColumn
            scrollByElementControl={false}
            keyField={SuggestKeywordsListColumns.SUGGEST_KEYWORD}
            data={filteredProductsHotKeywords}
            columns={columns}
            noDataHint={'limitation.labels.suggestKeywordsNoDataHint'}
            remote={true}
            hidePagination
          />
        </div>
      </div>
    );
  }, [loading, addKeyword, keywords, productsHotKeywords]);

  const error = get(errors, 'include.searchKeywords');
  const tagsClassName = useMemo(() => cx(styles.tags, {
    hasError: error !== undefined
  }), [error]);
  const colClassName = useMemo(() => cx(styles.col, {
    hasError: error !== undefined
  }), [error]);

  const onEnterPress = useCallback((e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleAddSearchString();
    }
  }, [handleAddSearchString]);

  let errorTip;
  if (error) {
    if (Array.isArray(error)) {
      errorTip = error.map((err, index) => <div key={index} className={'errorTip'}>{err}</div>);
    } else {
      errorTip = <div className={'errorTip'}>{error}</div>;
    }
  }

  return (
    <fieldset>
      {(!inited && loading) && <LoadingIndicator/>}
      <legend>
        <span>{i18n.t<string>('limitation.labels.searchKeywordsTitle')}</span>
      </legend>
      <div className={styles.keywordSettingContainer}>
        <div className={styles.inputArea}>
          <div className={styles.descript}>
            {i18n.t<string>('limitation.labels.searchKeywordsDes')}
          </div>
          <div className={colClassName} onClick={handleOnClickCol}>
            {tagsValue.length > 0 &&
              <Tags
                className={tagsClassName}
                value={tagsValue}
                onChange={handleOnTagsChange}
              />
            }
            <Form.Control
              ref={keywordInputRef}
              type='text'
              name='searchKeywords'
              autoComplete='off'
              onKeyDown={onEnterPress}
              placeholder={i18n.t<string>('limitation.placeholders.searchKeywords')}
            />
            <div className={styles.clearButton} onClick={handleRemoveAllKeywords}>
              <img src={closeSmallIcon} alt={'close'}/>
            </div>
          </div>
          {errorTip}
        </div>
        {renderTrendingKeywordsList}
        {renderSuggestKeywordsList}
      </div>
    </fieldset>
  );
};
