import { getDataProvider } from '../ReportTable/ReportDataProviderFactory';
import { ReportDimension, ReportGran, ReportType } from 'core/report/ReportData';
import i18n from 'i18n';
import { AbstactReportContentModel } from '../ReportContentModel';
import moment from 'moment-timezone';

export class PerformanceReportModel extends AbstactReportContentModel {

  get title () {
    return i18n.t<string>('report.labels.performanceTitle');
  }

  get defaultReportType () {
    return ReportType.PERFORMANCE;
  }

  get defaultReportDimension () {
    return ReportDimension.DAY;
  }

  get defaultReportGran () {
    return ReportGran.DAY;
  }

  get validDimensions () {
    return [ReportDimension.HOUR, ...this.state.tableDimensions];
  }

  get reportTypes () {
    return [{
      label: i18n.t<string>(`report.labels.${ReportType.PERFORMANCE}Type`),
      value: ReportType.PERFORMANCE
    }, {
      label: i18n.t<string>(`report.labels.${ReportType.E_COMMERCE}Type`),
      value: ReportType.E_COMMERCE
    }];
  }

  get reportGrans () {
    if (this.state.reportType === ReportType.E_COMMERCE) {
      return [
        {
          label: i18n.t<string>(`report.labels.${ReportGran.DAY}`),
          value: ReportGran.DAY
        }
      ];
    }
    return Object.values(ReportGran)
      .filter(reportGran => reportGran !== ReportGran.MONTH)
      .map(reportGran => {
        return {
          label: i18n.t<string>(`report.labels.${reportGran}`),
          value: reportGran
        };
      }
    );
  }

  getExcelData = () => {
    const {
      tableColumnSettings,
      tableData,
      dimension
    } = this.state;

    const excelColumnSetteings = tableColumnSettings.filter(setting => setting.dataField !== 'editBtns');
    const dimensionNeedId = [ReportDimension.PRODUCT, ReportDimension.CREATIVE].includes(dimension);
    const getHeader = (setting) => {
      return i18n.t<string>(setting.text);
    };
    const metricsMapI18n = excelColumnSetteings.reduce((acc, setting) => {
      acc[setting.dataField] = getHeader(setting);
      return acc;
    }, {});
    const metricsI18nMapTotal = excelColumnSetteings.reduce((acc, setting) => {
      acc[getHeader(setting)] = setting.footer;
      return acc;
    }, {});

    if (dimensionNeedId) {
      metricsMapI18n.id = i18n.t<string>('report.labels.id');
      metricsI18nMapTotal[i18n.t<string>('report.labels.id')] = '';
    }

    const textDecorators = excelColumnSetteings.reduce((acc, setting) => {
      acc[setting.dataField] = setting.formatExtraData.textDecorator;
      return acc;
    }, {});

    const excelDataList = tableData.reduce((excelData, rowData) => {
      const newRowData = {};
      Object.keys(rowData).filter(key => !!metricsMapI18n[key]).forEach(key => {
        const textDecorator = textDecorators[key];
        newRowData[metricsMapI18n[key]] = textDecorator ? textDecorator(rowData[key]) : rowData[key];
      });
      excelData.push(newRowData);
      return excelData;
    }, []);
    excelDataList.push(metricsI18nMapTotal);
    return excelDataList;
  }

  download = async () => {
    const excelDataList = this.getExcelData();
    const XLSXModule = await import(/* webpackChunkName: "xlsx" */ 'xlsx');
    const XLSX = XLSXModule.default;
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excelDataList);
    XLSX.utils.book_append_sheet(wb, ws, 'report');
    XLSX.writeFile(wb, 'report.xlsx');
  }

  canFilterSelect = (filterName: string) => {
    return false;
  }

  canFilterRemove = () => {
    return true;
  }

  initReportData = async () => {
    this.reportType = ReportType.PERFORMANCE;
    this.dataProvider = getDataProvider(this.reportType, this.queryDataWithFilter, this.onDateClick, this.onEditClick);
    this.dimension = ReportDimension.DAY;
    this.gran = ReportGran.DAY;
    this.filter = {};
    this.from = moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
    this.to = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
    this.searchString = '';
    this.selectedTagFilter = [];
    this.timezone = this.localeMeta ? this.localeMeta.timezone : '+08:00';
    this.setUpReportTableData();
  }

  onDateClick = async (date) => {
    this.from = moment(date).startOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    this.to = moment(date).endOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    if (this.gran === ReportGran.DAY) {
      this.gran = ReportGran.HOUR;
      this.dimension = ReportDimension.HOUR;
    }
    this.updateSearchPath(false);
  }
}
