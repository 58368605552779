import _ from 'lodash';
import { ReportDataProvider } from './ReportDataProvider';
import { tableColumnSettings } from './reportTableColumnSettings';
import { Metrics, ReportDimension, ReportRecord } from 'core/report/ReportData';
import { divideValue } from './ReportDataHelper';
import { hasRoles } from 'core/permission/PermissionDSL';
import { RoleNames, Actor } from 'core';

enum COLUMNS {
  NAME = 'name',
  IMPRES = 'impres',
  CLICKS = 'clicks',
  CTR = 'ctr',
  CPM = 'cpm',
  CPC = 'cpc',
  SPENT = 'spent',
  DIRECT_ADD_TO_CART = 'directAddToCart',
  DIRECT_ORDER = 'directOrder',
  DIRECT_PRODUCT_SALES_COUNT = 'directProductSalesCount',
  DIRECT_PRODUCT_SALES_AMOUNT = 'directProductSalesAmount',
  DIRECT_CONVERSION_RATE = 'directConversionRate',
  DIRECT_ROAS = 'directRoas',
  INDIRECT_ADD_TO_CART = 'indirectAddToCart',
  INDIRECT_ORDER = 'indirectOrder',
  INDIRECT_PRODUCT_SALES_COUNT = 'indirectProductSalesCount',
  INDIRECT_PRODUCT_SALES_AMOUNT = 'indirectProductSalesAmount',
  INDIRECT_CONVERSION_RATE = 'indirectConversionRate',
  INDIRECT_ROAS = 'indirectRoas',
  EDITBTNS = 'editBtns'
}

export class ECommerceReportDataProvider implements ReportDataProvider {

  onDimensionNameClick: any;
  onEditClick: any;
  constructor (onDimensionNameClick, onEditClick) {
    this.onDimensionNameClick = onDimensionNameClick;
    this.onEditClick = onEditClick;
  }

  get avalableDimensions () {
    return [
      ReportDimension.DAY,
      ReportDimension.AGENCY,
      ReportDimension.ADVERTISER,
      ReportDimension.ORDER,
      ReportDimension.L1_OBJECT,
      ReportDimension.L2_CHANNEL,
      ReportDimension.CAMPAIGN_TYPE,
      ReportDimension.CREATIVE,
      ReportDimension.CREATIVE_TYPE,
      ReportDimension.PRODUCT,
      ReportDimension.SEARCH_KEYWORD
    ];
  }

  getReportDimensions (actor: Actor | null): Array<ReportDimension> {
    const isSysAdmin = hasRoles(RoleNames.sysAdmin).visible({ actor });
    const isAdvertiser = hasRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales).visible({ actor });
    const forbidDimensions = [ReportDimension.MONTH, ReportDimension.HOUR];
    !isSysAdmin && forbidDimensions.push(ReportDimension.AGENCY);
    isAdvertiser && forbidDimensions.push(ReportDimension.ADVERTISER);
    return this.avalableDimensions.filter(dimension => forbidDimensions.indexOf(dimension) === -1);
  }

  getReportTableColumnSettings (allowMetrics: Array<Metrics>, tableData: any, dimension: ReportDimension, currency: string) {
    const allColumnSettings = tableColumnSettings(tableData, dimension, currency, this.onDimensionNameClick);
    const tableAllowMetrics = [
      ...allowMetrics,
      COLUMNS.NAME,
      COLUMNS.EDITBTNS,
      COLUMNS.INDIRECT_ADD_TO_CART,
      COLUMNS.INDIRECT_ORDER,
      COLUMNS.INDIRECT_PRODUCT_SALES_COUNT,
      COLUMNS.INDIRECT_PRODUCT_SALES_AMOUNT,
      COLUMNS.INDIRECT_CONVERSION_RATE,
      COLUMNS.INDIRECT_ROAS,
      COLUMNS.DIRECT_ADD_TO_CART,
      COLUMNS.DIRECT_ORDER,
      COLUMNS.DIRECT_PRODUCT_SALES_COUNT,
      COLUMNS.DIRECT_PRODUCT_SALES_AMOUNT,
      COLUMNS.DIRECT_CONVERSION_RATE,
      COLUMNS.DIRECT_ROAS
    ];
    if (allowMetrics.indexOf(Metrics.CLICKS) !== -1) {
      allowMetrics.indexOf(Metrics.IMPRES) !== -1 && tableAllowMetrics.push(COLUMNS.CTR);
    }
    return _.compact(
      Object.values(COLUMNS).map(column => {
        return tableAllowMetrics.indexOf(column) !== -1 &&
          allColumnSettings[column];
      })
    );
  }

  getReportTableData (records: Array<ReportRecord>) {
    return records.map(record => {
      const impres = _.get(record, 'impres', 0);
      const clicks = _.get(record, 'clicks', 0);
      const spent = _.floor(_.get(record, 'spent', 0), 2);
      const indirectProductSalesCount = _.get(record, 'indirectProductSalesCount', 0);
      const indirectProductSalesAmount = _.get(record, 'indirectProductSalesAmount', 0);
      const directProductSalesCount = _.get(record, 'directProductSalesCount', 0);
      const directProductSalesAmount = _.get(record, 'directProductSalesAmount', 0);
      return {
        key: record.dimensionName + _.get(record, 'dimensionID', 0),
        id: _.get(record, 'dimensionID', 0).toString(),
        parentId: record.parentId,
        extra: record.extra,
        tags: record.tags,
        [COLUMNS.NAME]: record.dimensionName,
        [COLUMNS.IMPRES]: record.impres,
        [COLUMNS.CLICKS]: record.clicks,
        [COLUMNS.CTR]: divideValue(clicks * 100, impres),
        [COLUMNS.SPENT]: spent,
        [COLUMNS.DIRECT_ADD_TO_CART]: _.get(record, 'directAddToCart', 0),
        [COLUMNS.DIRECT_ORDER]: _.get(record, 'directOrder', 0),
        [COLUMNS.DIRECT_PRODUCT_SALES_COUNT]: directProductSalesCount,
        [COLUMNS.DIRECT_PRODUCT_SALES_AMOUNT]: directProductSalesAmount,
        [COLUMNS.DIRECT_CONVERSION_RATE]: divideValue(directProductSalesCount * 100, clicks, value => _.round(value, 2)),
        [COLUMNS.DIRECT_ROAS]: divideValue(directProductSalesAmount, spent),
        [COLUMNS.INDIRECT_ADD_TO_CART]: _.get(record, 'indirectAddToCart', 0),
        [COLUMNS.INDIRECT_ORDER]: _.get(record, 'indirectOrder', 0),
        [COLUMNS.INDIRECT_PRODUCT_SALES_COUNT]: indirectProductSalesCount,
        [COLUMNS.INDIRECT_PRODUCT_SALES_AMOUNT]: indirectProductSalesAmount,
        [COLUMNS.INDIRECT_CONVERSION_RATE]: divideValue(indirectProductSalesCount * 100, clicks, value => _.round(value, 2)),
        [COLUMNS.INDIRECT_ROAS]: divideValue(indirectProductSalesAmount, spent)
      };
    });
  }
}
