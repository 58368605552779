import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { Advertiser } from 'core/advertiser/Advertiser';
import { DefaultAdvertiserManager, AdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { AdvertiserMemberListModel, DefaultAdvertiserMemberListModel } from '../AdvertiserMemberList/AdvertiserMemberListModel';
import _ from 'lodash';
import { CompanyMember } from 'core';

export interface AdvertiserDetailModel {
  advertiser?: Advertiser;
  readonly advertiserId: string | number;
  readonly state: AdvertiserDetailState;
  readonly event: UpdateEventListener<AdvertiserDetailModel>;
  init (): Promise<void>;
  onUnmount (eventHandler?: number): void;
  getAdvertiserMemberListModel (): AdvertiserMemberListModel;
  switchList (listType: string | null, e): void;
}

export type AdvertiserDetailProps = {
  readonly model: AdvertiserDetailModel;
};

export type AdvertiserDetailState = {
  readonly loading: boolean;
  readonly advertiserViewData: any;
  readonly listType: any;
  readonly members: CompanyMember[];
};

export class DefaultAdvertiserDetailModel implements AdvertiserDetailModel {

  advertiser?: Advertiser;
  advertiserId: string | number;
  event: FireableUpdateEventListener<AdvertiserDetailModel>;
  manager: AdvertiserManager;
  handler?: number;
  advertiserViewData: any;
  loading: boolean;
  advertiserMemberListModel?: AdvertiserMemberListModel;
  listType: string;
  members: CompanyMember[];

  constructor (
    advertiserId: string | number,
    manager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    this.advertiserId = advertiserId;
    this.manager = manager;
    this.event = new FireableUpdateEventListener<AdvertiserDetailModel>();
    this.loading = false;
    this.members = [];
    this.listType = 'member';
  }

  get state (): AdvertiserDetailState {
    return {
      advertiserViewData: this.advertiserViewData,
      loading: this.loading,
      listType: this.listType,
      members: this.members
    };
  }

  onUnmount (eventHandler?: number) {
    this.advertiser = undefined;
    eventHandler !== undefined && this.event.remove(eventHandler);
  }

  getAdvertiserMemberListModel () {
    if (this.advertiserMemberListModel && this.advertiserMemberListModel.members === this.members) {
      return this.advertiserMemberListModel;
    }
    this.advertiserMemberListModel = new DefaultAdvertiserMemberListModel(this.advertiserId, this.members, this.refreshMember);
    return this.advertiserMemberListModel;
  }

  async init (): Promise<void> {
    this.updateState(true);
    try {
      this.advertiser = await this.manager.getAdvertiser(this.advertiserId);
      this.members = await this.manager.getAdvertiserMembers(this.advertiserId);
      this.genAdvertiserViewData();
    } catch (error) {}
    this.updateState(false);
  }

  refreshMember = async (): Promise<void> => {
    this.updateState(true);
    try {
      this.members = await this.manager.getAdvertiserMembers(this.advertiserId);
    } catch (error) {}
    this.updateState(false);
  }

  genAdvertiserViewData () {
    const advertiser = this.advertiser;
    if (!advertiser) {
      this.advertiserViewData = {};
      return;
    }
    this.advertiserViewData = _.omitBy({
      basic: _.omitBy({
        id: advertiser.id
      }, _.isUndefined)
    }, _.isUndefined);
  }

  switchList = (listType, e) => {
    e && e.stopPropagation();
    if (listType === null) {
      return;
    }
    this.listType = listType;
    this.updateState(false);
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
