import { AgencyDetail, Agency, Currency, AgencyWithPagination } from './Agency';
import {
  AgencyWebService,
  RestfulAgencyWebService
} from 'ws/AgencyWebService';
import { CompanyMember } from 'core/companyMember/CompanyMember';
import { SelectOptions } from 'components/commonType';
import { Pageable } from 'ws/Pageable';
export interface AgencyManager {
  getRTBDefaultMinBudgetPerDay (currency: string): number;
  getRTBDefaultMinBudget (currency: string): number;
  getAgencies (): Promise<Array<Agency>>;
  getAgenciesWithPagination (pageable: Pageable, searchString: string): Promise<AgencyWithPagination>;
  fetchAgency (agencyId: number): Promise<Agency>;
  updateAgency (request: AgencyDetail): Promise<number>;
  createAgency (request: AgencyDetail): Promise<number>;
  getMembers (agencyId: number): Promise<Array<CompanyMember>>;
  getAgenciesOptions (addonFeature?: string, extraFields?: string[]): Promise<Array<SelectOptions>>;
  getAgencyName (agencyId: number): Promise<string | null>;
}

const minBudgetPerDayMap = {
  [Currency.NTD]: 10
};

const minBudgetMap = {
  [Currency.NTD]: 10
};

export class DefaultAgencyManager implements AgencyManager {
  webService: AgencyWebService;

  constructor (webService: AgencyWebService = new RestfulAgencyWebService()) {
    this.webService = webService;
  }

  getRTBDefaultMinBudgetPerDay (currency: string): number {
    return minBudgetPerDayMap[currency];
  }

  getRTBDefaultMinBudget (currency: string): number {
    return minBudgetMap[currency];
  }

  getAgencies (addonFeature?: string): Promise<Array<Agency>> {
    return this.webService.getAgencies(addonFeature);
  }

  getAgenciesWithPagination (pageable: Pageable, searchString: string): Promise<AgencyWithPagination> {
    return this.webService.getAgenciesWithPagination(pageable, searchString);
  }

  async fetchAgency (agencyId: number): Promise<Agency> {
    return this.webService.getAgency(agencyId);
  }

  async updateAgency (request: AgencyDetail): Promise<number> {
    return this.webService.updateAgency(request);
  }

  async createAgency (request: AgencyDetail): Promise<number> {
    return this.webService.createAgency(request);
  }

  async getMembers (agencyId: number): Promise<Array<CompanyMember>> {
    return this.webService.getAgencyMember(agencyId);
  }

  async getAgenciesOptions (addonFeature?: string, extraFields?: string[]): Promise<Array<SelectOptions>> {
    return this.webService.getAgenciesOptions(addonFeature, extraFields);
  }

  async getAgencyName (agencyId: number): Promise<string | null> {
    return this.webService.getAgencyName(agencyId);
  }
}
