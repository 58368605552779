import { PMaxCore } from 'core';
import { AdvertiserHomeModel, DefaultAdvertiserHomeModel } from './AdvertiserHome/AdvertiserHomeModel';
import { AdvertiserFormModel, CreateAdvertiserFormModel, EditAdvertiserFormModel } from './AdvertiserForm/AdvertiserFormModel';
import { AdvertiserDetailModel, DefaultAdvertiserDetailModel } from './AdvertiserDetail/AdvertiserDetailModel';
import _ from 'lodash';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';

export interface AdvertisersModel {
  readonly state: AdvertisersState;
  readonly event: UpdateEventListener<AdvertisersModel>;
  getCreateAdvertiserFormModel (): AdvertiserFormModel;
  getEditAdvertiserFormModel (advertiserId): AdvertiserFormModel;
  getAdvertiserHomeModel (): AdvertiserHomeModel;
  getAdvertiserDetailModel (advertiserId): AdvertiserDetailModel;
  onUnmount (handler): void;
}

export type AdvertisersProps = {
  readonly model: AdvertisersModel;
};

export type AdvertisersState = {
  readonly loading: boolean;
};

export class DefaultAdvertisersModel implements AdvertisersModel {

  core: PMaxCore;
  advertiserHomeModel?: AdvertiserHomeModel;
  createAdvertiserFormModel?: AdvertiserFormModel;
  editAdvertiserFormModel?: AdvertiserFormModel;
  advertiserDetailModel?: AdvertiserDetailModel;
  loading: boolean = false;
  event: FireableUpdateEventListener<AdvertisersModel> = new FireableUpdateEventListener<AdvertisersModel>();

  constructor (
    core: PMaxCore
  ) {
    this.core = core;
  }

  get state () {
    return ({
      loading: this.loading
    });
  }

  getAdvertiserHomeModel () {
    const actor = this.core.authenticationManager.actor;
    let agencyId = _.get(actor, 'agencyId');
    agencyId = agencyId === null ? undefined : agencyId;
    if (this.advertiserHomeModel && agencyId === this.advertiserHomeModel.agencyId) {
      return this.advertiserHomeModel;
    }
    this.advertiserHomeModel = new DefaultAdvertiserHomeModel(actor!);
    return this.advertiserHomeModel;
  }

  getCreateAdvertiserFormModel (): AdvertiserFormModel {
    if (this.createAdvertiserFormModel) {
      return this.createAdvertiserFormModel;
    }
    this.createAdvertiserFormModel = new CreateAdvertiserFormModel(
      this.core.authenticationManager.actor!.agencyId
    );
    return this.createAdvertiserFormModel;
  }

  getEditAdvertiserFormModel (advertiserId): AdvertiserFormModel {
    if (this.editAdvertiserFormModel &&
      this.editAdvertiserFormModel.advertiserId === advertiserId
    ) {
      return this.editAdvertiserFormModel;
    }
    this.editAdvertiserFormModel = new EditAdvertiserFormModel(
      advertiserId
    );
    return this.editAdvertiserFormModel;
  }

  getAdvertiserDetailModel (advertiserId) {
    if (this.advertiserDetailModel &&
      advertiserId === this.advertiserDetailModel.advertiserId
    ) {
      return this.advertiserDetailModel;
    }
    this.advertiserDetailModel = new DefaultAdvertiserDetailModel(
      advertiserId
    );
    return this.advertiserDetailModel;
  }

  onUnmount (handler) {
    this.advertiserHomeModel = undefined;
    this.createAdvertiserFormModel = undefined;
    this.editAdvertiserFormModel = undefined;
    this.advertiserDetailModel = undefined;
    handler && this.event.remove(handler);
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
