import { RecommendBidPrice } from 'core/rtbCampaign/RtbCampaign';
import { PCHomeWebService, RestfulPCHomeWebService } from 'ws/PCHomeWebService';

export interface PCHomeManager {
  getRecommendBidPrice (keywords: string[]): Promise<RecommendBidPrice>;
}

export class DefaultPCHomeManager implements PCHomeManager {
  webService: PCHomeWebService;

  constructor (
    webService: PCHomeWebService = new RestfulPCHomeWebService()
  ) {
    this.webService = webService;
  }

  async getRecommendBidPrice (keywords: string[]): Promise<RecommendBidPrice> {
    if (keywords.length === 0) {
      return Promise.resolve({
        bidCap: 20,
        bidFloor: 5
      });
    }
    return this.webService.getRecommendBidPrice(keywords);
  }
}
