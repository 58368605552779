import { commaDecorator, percentDecorator, fractionDecorator, totalCostDecorator, divideValue, costDecorator } from './ReportDataHelper';
import { getReportTableFormatters } from './ReportTableFormatters';
import { ReportDimension } from 'core/report/ReportData';
import { renderColumn } from 'components/TableColumn/TableColumn';
import styles from './reportTable.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import { HeaderFormatter } from 'react-bootstrap-table-next';

export enum REPORT_COLUMNS {
  NAME = 'name',
  IMPRES = 'impres',
  CLICKS = 'clicks',
  CTR = 'ctr',
  CPM = 'cpm',
  CPC = 'cpc',
  MEDIA_COST = 'mediaCost',
  MEDIA_SPENT = 'mediaSpent',
  SPENT = 'spent',
  TAGS = 'tags',
  DIRECT_ADD_TO_CART = 'directAddToCart',
  DIRECT_ORDER = 'directOrder',
  DIRECT_PRODUCT_SALES_COUNT = 'directProductSalesCount',
  DIRECT_PRODUCT_SALES_AMOUNT = 'directProductSalesAmount',
  DIRECT_CONVERSION_RATE = 'directConversionRate',
  DIRECT_ROAS = 'directRoas',
  INDIRECT_ADD_TO_CART = 'indirectAddToCart',
  INDIRECT_ORDER = 'indirectOrder',
  INDIRECT_PRODUCT_SALES_COUNT = 'indirectProductSalesCount',
  INDIRECT_PRODUCT_SALES_AMOUNT = 'indirectProductSalesAmount',
  INDIRECT_CONVERSION_RATE = 'indirectConversionRate',
  INDIRECT_ROAS = 'indirectRoas',
  EDITBTNS = 'editBtns'
}

export const tableColumnSettings = (tableData: any, dimension: ReportDimension, currency: string, onDimensionNameClick, onDateClick?) => {
  const getCostSumFooterValue = (column: REPORT_COLUMNS) => {
    const columnData = tableData.map(data => _.get(data, column, 0));
    return columnData.reduce((acc, item) => acc + _.floor(item, 2), 0);
  };

  const getSumFooterValue = (column: REPORT_COLUMNS) => {
    const columnData = tableData.map(data => _.get(data, column, 0));
    return columnData.reduce((acc, item) => acc + item, 0);
  };

  const getFractionFooterValue = (numeratorColumn: REPORT_COLUMNS, denominatorColumn: REPORT_COLUMNS, multiply: number, decorator?: (value: number) => number) => {
    const numeratorData = tableData.map(data => _.get(data, numeratorColumn, 0));
    const denominatorData = tableData.map(data => _.get(data, denominatorColumn, 0));
    const sumOfNumerator = numeratorData.reduce((acc, item) => acc + item, 0);
    const sumOfDenominator = denominatorData.reduce((acc, item) => acc + item, 0);
    if (sumOfDenominator === 0) {
      return 0;
    }
    return divideValue(sumOfNumerator * multiply, sumOfDenominator, decorator);
  };

  const getCostFractionFooterValue = (numeratorColumn: REPORT_COLUMNS, denominatorColumn: REPORT_COLUMNS, multiply: number, decorator?: (value: number) => number) => {
    const numeratorData = tableData.map(data => _.get(data, numeratorColumn, 0));
    const denominatorData = tableData.map(data => _.get(data, denominatorColumn, 0));
    const sumOfNumerator = numeratorData.reduce((acc, item) => acc + _.floor(item, 2), 0);
    const sumOfDenominator = denominatorData.reduce((acc, item) => acc + _.floor(item, 2), 0);
    if (sumOfDenominator === 0) {
      return 0;
    }
    return divideValue(sumOfNumerator * multiply, sumOfDenominator, decorator);
  };

  const getColumnSetting = (column: REPORT_COLUMNS, formatter, footer, textDecorator, footerDecorator?: any, currency?: any, headerFormatter?: HeaderFormatter<any> | undefined) => {
    let headerName: any = column;
    if (column === REPORT_COLUMNS.NAME) {
      if (dimension === ReportDimension.MONTH || dimension === ReportDimension.DAY || dimension === ReportDimension.HOUR) {
        headerName = 'date';
      } else if (dimension === ReportDimension.PRODUCT) {
        headerName = 'product';
      } else if (dimension === ReportDimension.SEARCH_KEYWORD) {
        headerName = 'searchKeyword';
      }
    }
    const columnClassGetter = () => {
      return `${styles.reportCell} ${styles[column]}`;
    };
    const text = i18n.t(`reportTable.headers.${headerName}`);
    return renderColumn({
      sort: true,
      text: currency ? `${text} (${currency})` : text,
      dataField: column,
      footer: footerDecorator ? footerDecorator(footer) : textDecorator(footer),
      formatExtraData: {
        dimension,
        textDecorator
      },
      classes: columnClassGetter,
      headerClasses: columnClassGetter,
      footerClasses: columnClassGetter
    },
    formatter,
    headerFormatter);
  };
  const formatters = getReportTableFormatters(onDimensionNameClick, onDateClick);
  return {
    [REPORT_COLUMNS.NAME]: getColumnSetting(
      REPORT_COLUMNS.NAME,
      formatters.nameFormatter,
      i18n.t<string>('reportTable.footers.total'),
      _.identity
    ),
    [REPORT_COLUMNS.IMPRES]: getColumnSetting(
      REPORT_COLUMNS.IMPRES,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.IMPRES),
      commaDecorator
    ),
    [REPORT_COLUMNS.CLICKS]: getColumnSetting(
      REPORT_COLUMNS.CLICKS,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.CLICKS),
      commaDecorator
    ),
    [REPORT_COLUMNS.CTR]: getColumnSetting(
      REPORT_COLUMNS.CTR,
      formatters.textFormatter,
      getFractionFooterValue(REPORT_COLUMNS.CLICKS, REPORT_COLUMNS.IMPRES, 100),
      percentDecorator,
      undefined,
      undefined,
      formatters.headerWithHintFormatter
    ),
    [REPORT_COLUMNS.CPM]: getColumnSetting(
      REPORT_COLUMNS.CPM,
      formatters.textFormatter,
      getCostFractionFooterValue(REPORT_COLUMNS.SPENT, REPORT_COLUMNS.IMPRES, 1000),
      fractionDecorator,
      undefined,
      undefined,
      formatters.headerWithHintFormatter
    ),
    [REPORT_COLUMNS.CPC]: getColumnSetting(
      REPORT_COLUMNS.CPC,
      formatters.textFormatter,
      getCostFractionFooterValue(REPORT_COLUMNS.SPENT, REPORT_COLUMNS.CLICKS, 1),
      fractionDecorator,
      undefined,
      undefined,
      formatters.headerWithHintFormatter
    ),
    [REPORT_COLUMNS.MEDIA_COST]: getColumnSetting(
      REPORT_COLUMNS.MEDIA_COST,
      formatters.textFormatter,
      getCostSumFooterValue(REPORT_COLUMNS.MEDIA_COST),
      costDecorator,
      _.curry(totalCostDecorator)(currency),
      currency
    ),
    [REPORT_COLUMNS.MEDIA_SPENT]: getColumnSetting(
      REPORT_COLUMNS.MEDIA_SPENT,
      formatters.textFormatter,
      getCostSumFooterValue(REPORT_COLUMNS.MEDIA_SPENT),
      costDecorator,
      _.curry(totalCostDecorator)(currency),
      currency
    ),
    [REPORT_COLUMNS.SPENT]: getColumnSetting(
      REPORT_COLUMNS.SPENT,
      formatters.textFormatter,
      getCostSumFooterValue(REPORT_COLUMNS.SPENT),
      costDecorator,
      _.curry(totalCostDecorator)(currency),
      currency
    ),
    [REPORT_COLUMNS.TAGS]: getColumnSetting(
      REPORT_COLUMNS.TAGS,
      formatters.tagFormatter,
      '',
      _.identity
    ),
    [REPORT_COLUMNS.EDITBTNS]: {
      ...getColumnSetting(
        REPORT_COLUMNS.EDITBTNS,
        formatters.floatingEditBtnsFormatter,
        '',
        _.identity
      ),
      text: '',
      sort: false
    },
    [REPORT_COLUMNS.DIRECT_ADD_TO_CART]: getColumnSetting(
      REPORT_COLUMNS.DIRECT_ADD_TO_CART,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.DIRECT_ADD_TO_CART),
      commaDecorator,
      undefined,
      undefined,
      formatters.headerWithHintFormatter
    ),
    [REPORT_COLUMNS.DIRECT_ORDER]: getColumnSetting(
      REPORT_COLUMNS.DIRECT_ORDER,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.DIRECT_ORDER),
      commaDecorator,
      undefined,
      undefined,
      formatters.headerWithHintFormatter
    ),
    [REPORT_COLUMNS.DIRECT_PRODUCT_SALES_COUNT]: getColumnSetting(
      REPORT_COLUMNS.DIRECT_PRODUCT_SALES_COUNT,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.DIRECT_PRODUCT_SALES_COUNT),
      commaDecorator,
      undefined,
      undefined,
      formatters.headerWithHintFormatter
    ),
    [REPORT_COLUMNS.DIRECT_PRODUCT_SALES_AMOUNT]: getColumnSetting(
      REPORT_COLUMNS.DIRECT_PRODUCT_SALES_AMOUNT,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.DIRECT_PRODUCT_SALES_AMOUNT),
      costDecorator,
      undefined,
      currency,
      formatters.headerWithHintFormatter
    ),
    [REPORT_COLUMNS.INDIRECT_ADD_TO_CART]: getColumnSetting(
      REPORT_COLUMNS.INDIRECT_ADD_TO_CART,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.INDIRECT_ADD_TO_CART),
      commaDecorator,
      undefined,
      undefined,
      formatters.headerWithHintFormatter
    ),
    [REPORT_COLUMNS.INDIRECT_ORDER]: getColumnSetting(
      REPORT_COLUMNS.INDIRECT_ORDER,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.INDIRECT_ORDER),
      commaDecorator,
      undefined,
      undefined,
      formatters.headerWithHintFormatter
    ),
    [REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_COUNT]: getColumnSetting(
      REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_COUNT,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_COUNT),
      commaDecorator,
      undefined,
      undefined,
      formatters.headerWithHintFormatter
    ),
    [REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_AMOUNT]: getColumnSetting(
      REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_AMOUNT,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_AMOUNT),
      costDecorator,
      undefined,
      currency,
      formatters.headerWithHintFormatter
    ),
    [REPORT_COLUMNS.DIRECT_CONVERSION_RATE]: getColumnSetting(
      REPORT_COLUMNS.DIRECT_CONVERSION_RATE,
      formatters.textFormatter,
      getFractionFooterValue(REPORT_COLUMNS.DIRECT_PRODUCT_SALES_COUNT, REPORT_COLUMNS.CLICKS, 100, value => _.round(value, 2)),
      percentDecorator,
      undefined,
      undefined,
      formatters.headerWithHintFormatter
    ),
    [REPORT_COLUMNS.DIRECT_ROAS]: getColumnSetting(
      REPORT_COLUMNS.DIRECT_ROAS,
      formatters.textFormatter,
      getCostFractionFooterValue(REPORT_COLUMNS.DIRECT_PRODUCT_SALES_AMOUNT, REPORT_COLUMNS.SPENT, 1, value => _.round(value, 2)),
      fractionDecorator,
      undefined,
      undefined,
      formatters.headerWithHintFormatter
    ),
    [REPORT_COLUMNS.INDIRECT_CONVERSION_RATE]: getColumnSetting(
      REPORT_COLUMNS.INDIRECT_CONVERSION_RATE,
      formatters.textFormatter,
      getFractionFooterValue(REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_COUNT, REPORT_COLUMNS.CLICKS, 100, value => _.round(value, 2)),
      percentDecorator,
      undefined,
      undefined,
      formatters.headerWithHintFormatter
    ),
    [REPORT_COLUMNS.INDIRECT_ROAS]: getColumnSetting(
      REPORT_COLUMNS.INDIRECT_ROAS,
      formatters.textFormatter,
      getCostFractionFooterValue(REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_AMOUNT, REPORT_COLUMNS.SPENT, 1, value => _.round(value, 2)),
      fractionDecorator,
      undefined,
      undefined,
      formatters.headerWithHintFormatter
    )
  };
};
