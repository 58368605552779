import React, { Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import classNames from 'classnames/bind';
import i18n from 'i18next';
import { Nav } from 'react-bootstrap';
import { renderColumn } from 'components/TableColumn/TableColumn';
import { CampaignListState, CampaignListProps } from './CampaignListModel';
import { CampaignListColumns } from './CampaignListColumnSetting';
import { faPencilAlt, faChartArea, faEye, faTrashAlt, faCopy, faCut } from '@fortawesome/free-solid-svg-icons';
import styles from './campaignList.module.scss';
import { SearchBar } from 'components/SearchBar';
import Progress from 'components/Progress/Progress';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { Modal } from 'components/Modal/Modal';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { Permission } from 'core/auth/Permission';
import { addOnEnabled, hasFuncs, inCondition, notSettleOrder } from 'core/permission/PermissionDSL';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { ObjectFitContainer } from 'components/ObjectFitContainer/ObjectFitContainer';
import _, { compact } from 'lodash';
import { Status } from 'components/Status/Status';
import { AdType, CampaignState, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { PermissionItem } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { formatPrice, formatPriceAny } from 'helper/CurrencyHelper';
import Select from 'components/Select/Select';
import { Trans } from 'react-i18next';
import { ListEditBar } from 'components/ListEditBar/ListEditBar';
import { FilterMenuTab } from 'components/FilterMenuTab/FilterMenuTab';
import { BidStrategy } from 'core/l2Object/L2Object';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { LimitationTableCell } from 'components/LimitationTableCell/LimitationTableCell';
import { LabelValueColumn } from 'components/TableColumn/LabelValueColumn';
import { PmpType } from 'core/pmp/Pmp';
import { StateTableCell } from 'components/StateTableCell/StateTabelCell';

export class CampaignList extends React.Component<RouteComponentProps<{}> & CampaignListProps, CampaignListState> {
  cssClassNames: any;
  handler?: number;
  columnFormatterMap: any;
  campaignWritePermission: PermissionItem;

  constructor (props) {
    super(props);
    this.state = props.model.state;
    this.cssClassNames = classNames.bind(styles);
    this.campaignWritePermission = hasFuncs(Permission.CAMPAIGN_WRITE)
      .and(addOnEnabled(ADDONFEATURE.CHANNEL[_.get(props.model.l1Object, 'channel', L1ObjectChannel.RTB)]));
    this.columnFormatterMap = {
      [CampaignListColumns.NAME]: this.nameFormatter,
      [CampaignListColumns.STATE]: this.stateFormatter,
      [CampaignListColumns.DELIVERY]: this.deliveryFormatter,
      [CampaignListColumns.CREATIVE]: this.creativeFormatter,
      [CampaignListColumns.SCHEDULE]: this.scheduleFormatter,
      [CampaignListColumns.PROGRESS]: this.progressFormatter,
      [CampaignListColumns.BUDGET]: this.budgetFormatter,
      [CampaignListColumns.PRICE]: this.priceFormatter,
      [CampaignListColumns.OPTIMIZE]: this.optimizationFormatter,
      [CampaignListColumns.LIMITATION]: this.limitationFormatter,
      [CampaignListColumns.IMPRES]: this.descFormatter,
      [CampaignListColumns.CLICKS]: this.descFormatter,
      [CampaignListColumns.CPC]: this.performanceDataFormatter.bind(this, 2),
      [CampaignListColumns.CTR]: this.dataPercentFormatter,
      [CampaignListColumns.SPENT]: this.spentFormatter,
      [CampaignListColumns.TAGS]: this.tagsFormatter,
      [CampaignListColumns.EDITBTNS]: this.floatingEditBtnsFormatter
    };
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    if (this.props.model.searchString !== '') {
      this.props.model.onSearchChange(this.props.model.searchString);
    }
    if (this.props.model.state.needUpdateViewModelData) {
      this.props.model.updateViewModelData();
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
    if (this.props.model.state.needUpdateViewModelData) {
      this.props.model.updateViewModelData();
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  getCampaignEditPermission (campaign, allowPmp = false) {
    const permission = _.clone(this.campaignWritePermission).and(inCondition(() => campaign.state !== CampaignState.DELETE));
    return allowPmp ? permission : permission.and(inCondition(() => campaign.adType !== AdType.PMP));
  }

  nameHeaderFormatter = (column: any, columnIndex: number, { sortElement }) => {
    const model = this.props.model;
    const allCampaignLength = model.state.viewModelData ? model.state.viewModelData.length : model.campaignList.length;
    const selectedObjectsLength = model.state.selectedCampaign.length;
    const isDeletedList = model.state.selectedStateFilter.includes(i18n.t<string>('campaignList.labels.deleteState'));
    return (
      <div className={styles.nameHeader}>
        <PermissionChecker permissionAware={_.clone(this.campaignWritePermission).and(inCondition(() => !isDeletedList)).and(notSettleOrder(model.order))}>
          <input
            type='checkbox'
            checked={allCampaignLength === selectedObjectsLength}
            ref={el => el && (el.indeterminate = allCampaignLength !== selectedObjectsLength && selectedObjectsLength > 0)}
            onChange={model.handleOnSelectAll}
            id={'input0'}
          />
          <label htmlFor='input0' />
        </PermissionChecker>
        {i18n.t<string>(column.text)}
        {sortElement}
      </div>
    );
  }

  nameFormatter = (columnData: any, campaign: any): any => {
    if (columnData.summary) {
      return (
        <div>{columnData.summary}</div>
      );
    }

    const handleOnSelect = (e) => {
      this.props.model.handleOnSelect(campaign);
    };

    const renderLabel = () => <span className={styles.campaignName}>{columnData.name}</span>;
    const campaignId: number = campaign.id;
    const objectIsChecked = this.props.model.state.selectedCampaign.indexOf(campaignId) > -1;
    const editPath = `${this.props.match.url}/campaigns/${campaignId}/${this.props.model.editCampaignPath}`;
    return (
      <div className={styles.campaignCell}>
        <div className={styles.selectCheckBox}>
          <PermissionChecker permissionAware={this.getCampaignEditPermission(campaign, true).and(notSettleOrder(this.props.model.order))}>
            <input
              type='checkbox'
              checked={objectIsChecked}
              onChange={handleOnSelect}
              id={`input${columnData.listId}`}
            />
            <label htmlFor={`input${columnData.listId}`} />
          </PermissionChecker>
        </div>
        <div className={styles.info}>
          <PermissionChecker
            permissionAware={
              this.getCampaignEditPermission(campaign)
                .and(notSettleOrder(this.props.model.order))
            }
            renderOtherwise={renderLabel}
          >
            <Link
              to={editPath}
              className={`${styles.campaignName} ${styles.link}`}
            >
              {columnData.name}
            </Link>
          </PermissionChecker>
          <div className={styles.campaignID}>
            <div>
              {`ID: ${columnData.listId}`}
            </div>
            <div>
              <ObjectFitContainer
                key={i18n.language}
                containerHeight={16}
                onlyScaleDown={true}
                alignItems='auto'
                justifyContent='auto'
                margin={0}
              >
                {columnData.typeDes}
              </ObjectFitContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }

  creativeFormatter = (columnData: any, campaignData: any): any => {
    const clickFunc = () => {
      this.props.history.replace(`${this.props.location.pathname}?campaignIds=${campaignData.id}&action=manage`);
    };
    const renderCheckButton = () => (
      <IconWithTooltip
        className={styles.detailBtn}
        onClick={clickFunc}
        icon={faEye}
        tooltipProps={{
          id: `campaignListCreativeViewHint-${campaignData.id}`,
          tooltip: i18n.t<string>('campaignList.labels.creativeViewHint')
        }}
      />
    );
    return (
      columnData !== undefined ?
        <div className={styles.campaignCell}>
          {columnData ? columnData : i18n.t<string>('creativeList.labels.none')}
          <PermissionChecker
            permissionAware={this.getCampaignEditPermission(campaignData, true).and(notSettleOrder(this.props.model.order))}
            renderOtherwise={renderCheckButton}
          >
            <IconWithTooltip
              placement='right'
              className={styles.campaignEditBtn}
              onClick={clickFunc}
              icon={faPencilAlt}
              tooltipProps={{
                id: `campaignListCreativeEditHint-${campaignData.id}`,
                tooltip: i18n.t<string>('campaignList.labels.creativeEditHint')
              }}
            />
          </PermissionChecker>
        </div> :
        <div />
    );
  }

  scheduleFormatter = (columnData: any, campaign: any): any => {
    return (
      columnData ?
        <div className={styles.campaignCell}>
          <div className={styles.scheduleContent}>
            <Trans i18nKey='campaignList.labels.schedule'>
              {{ start: columnData.start }}<br/>to<br/>{{ end: columnData.end }}
            </Trans>
          </div>
        </div> :
        <div />
    );
  }

  progressFormatter = (columnData: any): any => {
    return (
      columnData ?
        <div className={styles.campaignCell}>
          <div className={styles.progressContent}>
            <div className={styles.progress}>
              <Progress
                progress={columnData.executeRate}
                danger={columnData.danger}
                deepWarning={columnData.deepWarning}
                warning={columnData.warning}
              />
            </div>
            {`${columnData.spents} / ${columnData.budget}`}
            <br />
            {`${(columnData.executeRate * 100).toFixed(2)}% / ${(columnData.predictRate * 100).toFixed(2)}%`}
          </div>
        </div> :
        <div />
    );
  }

  budgetFormatter = (columnData: any, campaign: any): any => {
    if (columnData.summary) {
      return (
        <div>{columnData.summary}</div>
      );
    }
    const budgetLabel = columnData.budgetDes ? (
      <>
        {i18n.t<string>('campaignList.labels.budget')}
        <br />
      </>
    ) : undefined;
    const budgetValue = columnData.budgetDes ? (
      <>
        : {columnData.budgetDes}
        <br />
      </>
    ) : undefined;
    if (campaign.pmp) {
      return (
        <LabelValueColumn
          options={
            compact([{
              label: i18n.t<string>('pmp.labels.pmpType'),
              value: i18n.t<string>(`pmp.pmpTypes.${_.camelCase(campaign.pmp.pmpType)}`)
            }, {
              label: i18n.t<string>('campaignList.labels.budget'),
              value: columnData.budgetDes
            }, campaign.pmp.pmpType === PmpType.PROGRAMMATIC_GUARANTEED ? {
              label: i18n.t<string>('pmp.labels.targetType'),
              value: i18n.t<string>(`pmp.targetTypes.${_.camelCase(campaign.pmp.targetType)}`)
            } : undefined, campaign.pmp.pmpType === PmpType.PROGRAMMATIC_GUARANTEED ? {
              label: i18n.t<string>('pmp.labels.targetQuantity'),
              value: campaign.pmp.targetQuantity
            } : undefined])
          }
        />
      );
    }
    return (
      <div className={styles.campaignCell}>
        <div className={styles.budgetContent}>
          <div>
            {budgetLabel}
            {i18n.t<string>('campaignList.labels.distribute')}
            <br />
            {columnData.deliverTypeDes && i18n.t<string>('campaignList.labels.deliverType')}
          </div>
          <div>
            {budgetValue}
            : {columnData.distributeDes}
            <br />
            {columnData.deliverTypeDes && `: ${columnData.deliverTypeDes}`}
          </div>
        </div>
      </div>
    );
  }

  priceFormatter = (columnData: any): any => {
    return columnData.priceModel;
  }

  limitationFormatter = (columnData: any, campaignData: any): any => {

    return (
      columnData ?
        <LimitationTableCell summaryData={columnData} l2ChannelId={campaignData.id}/> :
        <div />
    );
  }

  performanceDataFormatter = (placement, columnData: any): any => {
    if (!columnData) {
      return <div />;
    }
    if (columnData.summary !== undefined) {
      return <div>{columnData.summary}</div>;
    }
    const value = columnData.value ? columnData.value : 0;
    return (
      <div className={styles.campaignCell}>
        <div className={styles.performanceDataContent}>
          {_.floor(value, placement).toFixed(placement)}
        </div>
      </div>
    );
  }

  dataPercentFormatter = (columnData: any): any => {
    if (columnData.summary !== undefined) {
      return <div>{`${columnData.summary}%`}</div>;
    }

    return (
      <div className={styles.campaignCell}>
        <div className={styles.performanceDataContent}>
          {`${columnData.value ? _.floor(columnData.value, 2).toFixed(2) : '0.00'}%`}
        </div>
      </div>
    );
  }

  descFormatter = (columnData: any): any => {
    if (columnData.summary !== undefined) {
      return <div>{`${columnData.summary}`}</div>;
    }

    return (
      <div className={styles.campaignCell}>
        <div className={styles.performanceDataContent}>
          {columnData.desc}
        </div>
      </div>
    );
  }

  spentFormatter = (columnData: any): any => {
    return formatPrice(this.props.model.order.currency, columnData);
  }

  tagsFormatter = (columnData: any): any => {
    const renderTags = () => {
      return columnData.map(tag => {
        return (
          <div key={tag}>
            {tag}
          </div>
        );
      });
    };
    return (
      columnData ?
        <div className={styles.campaignCell}>
          {columnData.length > 0 ?
            <div className={styles.tagsContent}>
              {renderTags()}
            </div> :
            i18n.t<string>('campaignList.labels.emptyContent')
          }
        </div> :
        <div />
    );
  }

  optimizationFormatter = (optimize: string, campaign: any): any => {
    const optimization = i18n.t<string>(`optimizationGoal.${optimize.toLowerCase()}`).toLowerCase();
    const bidStrategy = _.isNumber(campaign.bidPrice) ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP;
    const currency = this.props.model.order.currency;
    return (
      <div className={styles.optimizationCell}>
        <div className={styles.title}>
          {i18n.t<string>('campaignForm.labels.optimizationGoal')}
        </div>
        <div>:</div>
        <div>
          {optimization}
        </div>
        <div className={styles.title}>
          {i18n.t<string>('campaignForm.labels.bidStrategy')}
        </div>
        <div>:</div>
        <div>
          {i18n.t<string>(`l1Object.labels.bidStrategy.${bidStrategy.toLowerCase()}`)}
        </div>
        <div className={styles.title}>
          {i18n.t<string>('campaignForm.labels.bidControl')}
        </div>
        <div>:</div>
        <div>
          {campaign.bidPrice ?
          `${currency} ${formatPriceAny(+(campaign.bidPrice))}` :
           i18n.t<string>('common.labels.noData')
          }
        </div>
        <div className={styles.title}>
          {i18n.t<string>('campaignForm.labels.billingEvent')}
        </div>
        <div>:</div>
        <div>
          {
            campaign.priceModel === RtbCampaignPlanType.RS ?
              i18n.t<string>(`rtbCampaign.billingEvent.${campaign.priceModel}.${campaign.optimize.toLowerCase()}`) :
              i18n.t<string>(`rtbCampaign.billingEvent.${campaign.priceModel}`)
          }
        </div>
      </div>
    );
  }

  floatingEditBtnsFormatter = (cell: any, campaign: any): any => {
    const campaignId: number = campaign.id;
    const editPath = `${this.props.match.url}/campaigns/${campaignId}/${this.props.model.editCampaignPath}`;

    const handleShowDeleteConfirmModal = this.showDeleteConfirmModal([campaignId]);
    const canDelete = this.props.model.canDeleteSelectedCampaigns([campaignId]);
    const canCopy = campaign.canNotCopyMessage === '';
    const copyMessage = canCopy ? i18n.t<string>('campaignList.labels.cloneHint') : campaign.canNotCopyMessage;
    const canEdit = campaign.canNotEditMessage === '';
    const editMessage = canEdit
     ? i18n.t<string>('campaignList.labels.editHint')
     : campaign.canNotEditMessage;
    const canSplit = campaign.canNotSlitMessage === '';
    const splitMessage = canSplit ? i18n.t<string>('campaignList.labels.splitHint') : campaign.canNotSlitMessage;
    return (
      <TableRowToolBar className={styles.campaignFloatingEditArea}>
        <PermissionChecker
          permissionAware={
            this.getCampaignEditPermission(campaign)
          }
        >
          <IconWithTooltip
            disabled={!canEdit}
            icon={faPencilAlt}
            tooltipProps={{
              id: `campaignListEditTip-${campaignId}`,
              link: editPath,
              tooltip: editMessage
            }}
          />
        </PermissionChecker>
        {
          <PermissionChecker permissionAware={this.getCampaignEditPermission(campaign)}>
            <IconWithTooltip
              disabled={!canCopy}
              icon={faCopy}
              tooltipProps={{
                id: `campaignListCloneTip-${campaignId}`,
                tooltip: copyMessage,
                link: `${this.props.match.url}/campaigns/${campaignId}/${this.props.model.copyCampaignPath}`
              }}
            />
          </PermissionChecker>
        }
        {this.props.model.showSplitBtn &&
          (<PermissionChecker permissionAware={this.getCampaignEditPermission(campaign)}>
            <IconWithTooltip
              disabled={!canSplit}
              icon={faCut}
              tooltipProps={{
                id: `campaignListSplitTip-${campaignId}`,
                tooltip: splitMessage,
                link: `${this.props.match.url}/campaigns/${campaignId}/split`
              }}
            />
          </PermissionChecker>)
        }
        <PermissionChecker permissionAware={hasFuncs(Permission.REPORT_ADS)}>
          <IconWithTooltip
            icon={faChartArea}
            tooltipProps={{
              id: `campaignListReportTip-${campaignId}`,
              link: this.props.model.getReportLink(campaign),
              tooltip: i18n.t<string>('campaignList.labels.reportHint')
            }}
          />
        </PermissionChecker>
        <PermissionChecker permissionAware={this.getCampaignEditPermission(campaign).and(notSettleOrder(this.props.model.order))}>
          <IconWithTooltip
            disabled={!canDelete}
            onClick={handleShowDeleteConfirmModal}
            icon={faTrashAlt}
            tooltipProps={{
              id: `campaignListCloneTip-${campaignId}`,
              tooltip: canDelete ? i18n.t<string>('campaignList.labels.deleteCampaign') : i18n.t<string>('campaignList.labels.cannotDelete')
            }}
          />
        </PermissionChecker>
      </TableRowToolBar>
    );
  }

  stateFormatter = (stateData: any, campaign: any): any => {
    const des = stateData.des;

    const renderHintButton = (handleHintDetail: () => void) => {
      if (des === i18n.t<string>('campaignList.labels.pmpSpacePartialSuspendState')) {
        return (
          <div className={styles.campaignCell}>
            <IconWithTooltip
              className={styles.detailBtn}
              onClick={handleHintDetail}
              icon={faEye}
              tooltipProps={{
                id: `campaignListPmpSpacePartialSuspendHint-${campaign.id}`,
                tooltip: i18n.t<string>('campaignList.labels.pmpSpacePartialSuspendHint')
              }}
            />
          </div>
        );
      } else {
        return <div />;
      }
    };
    const renderHintData = () => {
      if (des === i18n.t<string>('campaignList.labels.pmpSpacePartialSuspendState')) {
        const matchedSpaces = _.get(stateData, 'hintData', []);
        return (
          <>
            {i18n.t<string>('campaignList.labels.pmpSpacePartialSuspendHintTitle')}
            {_.map(matchedSpaces, (space) => (
              <div>
                {space.toString()}
              </div>
            ))}
          </>
        );
      } else {
        return <div />;
      }
    };

    return stateData ?
      <StateTableCell
        stateData={stateData}
        renderHintButton={renderHintButton}
        renderHintData={renderHintData}
      /> : <div />;
  }

  deliveryFormatter = (stateData: any): any => {
    return (
      <Status
        label={stateData.des}
        color={stateData.color}
        renderExtraInfo={stateData.extraInfo ? () => stateData.extraInfo : undefined}
      />
    );
  }

  handleNavEvent = (event) => {
    event && event.stopPropagation();
  }

  renderCampaignList = (viewModelData) => {
    const model = this.props.model;
    const rowClasses = (campaign, rowIndex) => {
      let classes: any = null;
      if (rowIndex === 0) {
        classes = styles.summaryRow;
      }

      if (model.isCampaignBudgetRemain(campaign)) {
        classes = [classes, styles.lightDanger].join(' ');
      }

      return classes;
    };

    const columns = model.state.columnsToShow.map(columnToRender => {
      let column = renderColumn(model.getColumnDefinition(columnToRender), this.columnFormatterMap[columnToRender]);
      if (columnToRender === CampaignListColumns.NAME) {
        column.headerFormatter = this.nameHeaderFormatter;
      }
      return column;
    });
    return (
      <StickableBootstrapTable
        stickFirstColumn
        stickLastColumn
        keyField='listId'
        data={viewModelData}
        summaryData={model.state.summaryData}
        columns={columns}
        defaultSorted={[{
          dataField: CampaignListColumns.NAME,
          order: 'desc'
        }]}
        rowClasses={rowClasses}
        noDataIndication={i18n.t<string>('campaignList.labels.noData')}
        hidePagination={true}
      />
    );
  }

  renderCreateAndFilter = () => {
    const model = this.props.model;
    const canCreate = model.canCreateCampaign;
    const createContainerClassName = this.cssClassNames('createCampaign', 'tipContainer', {
      block: !canCreate
    });
    return (
      <Fragment>
        <PermissionChecker permissionAware={this.campaignWritePermission}>
          <div className={createContainerClassName}>
            {!canCreate && model.canNotCreateMessage && <span className={styles.tooltip}>{model.canNotCreateMessage}</span>}
            <Nav.Item>
              <Nav.Link
                as={Link}
                to={`${this.props.location.pathname}/campaigns/${model.newCampaignPath}`}
                disabled={!canCreate}
                className='btn btn-tertiary btn-sm '
              >
                {i18n.t<string>('campaignList.labels.createCampaignBtn')}
              </Nav.Link>
            </Nav.Item>
          </div>
        </PermissionChecker>
        <FilterMenuTab
          filterMenuTabConfigs={model.filterMenuTabConfigs}
        />
        <div className={styles.campaignListTitleFilterArea}>
          <SearchBar
            model={{
              placeholder: i18n.t<string>('campaignList.placeholders.searchbar'),
              search: model.handleOnSearch,
              defaultValue: model.searchString
            }}
          />
        </div>
      </Fragment>
    );
  }

  getManageCreativesPath = () => {
    const campaignIds = this.props.model.state.selectedCampaign.join(',');
    let path = `${this.props.location.pathname}?`;
    if (campaignIds) {
      path = path + `campaignIds=${campaignIds}`;
    }
    return path;
  }

  bindCreatives = (event) => {
    event && event.stopPropagation();
    this.props.history.push(`${this.getManageCreativesPath()}&action=bind`);
  }

  activateCreatives = (event) => {
    event && event.stopPropagation();
    this.props.history.push(`${this.getManageCreativesPath()}&action=activate`);
  }

  deactivateCreatives = (event) => {
    event && event.stopPropagation();
    this.props.history.push(`${this.getManageCreativesPath()}&action=deactivate`);
  }

  renderEditBar = () => {
    const model = this.props.model;
    const showMultiTypeError = !model.allSelectedCampaignSameType();
    const showUnknowTypeError = model.selectUnknowAdTypeCampaign();
    const showDeleteTip = !model.canDeleteSelectedCampaigns(model.state.selectedCampaign);
    const dropdownContainerClassName = this.cssClassNames('tipContainer', {
      block: showMultiTypeError || showUnknowTypeError
    });
    const deleteButtonContainerClassName = this.cssClassNames('tipContainer', {
      block: showDeleteTip
    });
    const creativeErrorMessage = showUnknowTypeError ?
      i18n.t<string>('campaignList.labels.unknowTypeBatchEditError') :
      i18n.t<string>('campaignList.labels.batchEditMultiTypeError');
    const creativeCannotDeleteMessage = i18n.t<string>('campaignList.labels.cannotDelete');
    const handleShowDeleteConfirmModal = this.showDeleteConfirmModal(model.state.selectedCampaign);
    const selectedObjects = [...model.state.selectedCampaign];
    return (
      <ListEditBar selectedItems={selectedObjects} handleRemoveSelect={model.handleRemoveSelect}>
        <div className={dropdownContainerClassName}>
          <div className={styles.tooltip}>
            {creativeErrorMessage}
          </div>
          <ListEditBar.Dropdown
            title={i18n.t<string>('campaignList.labels.creative')}
            id='creativeAction'
            disabled={showMultiTypeError || showUnknowTypeError}
            onClick={this.handleNavEvent}
          >
            <ListEditBar.Dropdown.Item onClick={this.bindCreatives}>
              {i18n.t<string>('campaignList.labels.bindCreative')}
            </ListEditBar.Dropdown.Item>
            <ListEditBar.Dropdown.Item onClick={this.deactivateCreatives}>{i18n.t<string>('campaignList.labels.deactivateCreative')}</ListEditBar.Dropdown.Item>
            <ListEditBar.Dropdown.Item onClick={this.activateCreatives}>{i18n.t<string>('campaignList.labels.activeCreative')}</ListEditBar.Dropdown.Item>
          </ListEditBar.Dropdown>
        </div>
        <ListEditBar.Item>
          <ListEditBar.Link onClick={model.activeCampaign}>
            {i18n.t<string>('campaignList.labels.activeCampaign')}
          </ListEditBar.Link>
        </ListEditBar.Item>
        <ListEditBar.Item >
          <ListEditBar.Link onClick={model.deactiveCampaign}>
            {i18n.t<string>('campaignList.labels.deactivateCampaign')}
          </ListEditBar.Link>
        </ListEditBar.Item>
        <div className={deleteButtonContainerClassName}>
          <div className={styles.tooltip}>
            {creativeCannotDeleteMessage}
          </div>
          <ListEditBar.Item >
            <ListEditBar.Link disabled={showDeleteTip} onClick={handleShowDeleteConfirmModal}>
              {i18n.t<string>('campaignList.labels.deleteCampaign')}
            </ListEditBar.Link>
          </ListEditBar.Item>
        </div>
      </ListEditBar>
    );
  }

  showDeleteConfirmModal = (campaignIds) => (event?: any) => {
    event && event.stopPropagation();
    this.props.model.deleteCampaignIds = campaignIds;
    this.props.model.setVisibilityOfDeleteConfirmModal(true);
  }

  hideDeleteConfirmModal = () => {
    this.props.model.deleteCampaignIds = [];
    this.props.model.setVisibilityOfDeleteConfirmModal(false);
  }

  deleteCampaign = (e) => {
    this.props.model.deleteCampaign(this.props.model.deleteCampaignIds);
    this.props.model.setVisibilityOfDeleteConfirmModal(false);
  }

  renderDeleteConfirmModal = () => {
    return (
      <Modal
        title={i18n.t<string>('campaignList.labels.deleteModalTitle')}
        secondaryButton={{ title: i18n.t<string>('common.buttons.cancel'), callback: this.hideDeleteConfirmModal }}
        primaryButton={{ title: i18n.t<string>('campaignList.labels.deleteCampaign'), callback: this.deleteCampaign }}
        dismiss={this.hideDeleteConfirmModal}
      >
        {i18n.t<string>('campaignList.labels.deleteModalContent')}
      </Modal>
    );
  }

  render = () => {
    const model = this.props.model;
    const viewModelData = model.state.viewModelData;
    const selectedObjects = [...model.state.selectedCampaign];
    const hasSelectCampaign = selectedObjects.length > 0;
    return (
      <div className={styles.campaignList}>
        {model.state.loading && <LoadingIndicator />}
        <div className={styles.campaignListTitleBar}>
          {hasSelectCampaign ? this.renderEditBar() : this.renderCreateAndFilter()}
        </div>
        <div className={styles.contentArea}>
          <div className={styles.metricsOptionsContainer}>
            <Select
              className={styles.metricsOptions}
              options={model.getTabs()}
              name='metricsGroupOptions'
              simpleValue
              value={model.state.seletedTab}
              onChange={model.showTable}
            />
          </div>
          <div className={styles.listContainer}>
            {this.renderCampaignList(viewModelData)}
          </div>
        </div>
        {model.state.showDeleteConfirmModal &&
          this.renderDeleteConfirmModal()
        }
      </div>
    );
  }
}
