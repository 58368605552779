import { ReportType } from 'core/report/ReportData';
import { ReportDataProvider } from './ReportDataProvider';
import { GeneralReportDataProvider } from './GeneralReportDataProvider';
import { ECommerceReportDataProvider } from './ECommerceReportDataProvider';

export function getDataProvider (reportType: ReportType, onDimensionNameClick, onDateClick, onEditClick): ReportDataProvider {
  return reportType === ReportType.PERFORMANCE ?
    new GeneralReportDataProvider(onDimensionNameClick, onDateClick, onEditClick) :
    new ECommerceReportDataProvider(onDimensionNameClick, onEditClick);
}
