import React, { ReactElement } from 'react';
import { FormikField } from 'components/form/field/FormikField';
import i18n from 'i18n';

export const RTBCampaignGroupForm: React.FC<{
  currency: string,
  getBudgetTips: () => ReactElement | string | undefined
}> = ({
  currency,
  getBudgetTips
}) => {

  return (
    <FormikField.InputGroup
      label={i18n.t<string>('l1Object.labels.budget')}
      name='budget'
      type='number'
      prefix={currency}
      hint={getBudgetTips()}
    />
  );
};
