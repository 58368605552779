import { AdType, DailyBudgetPlan, DeliverType, RecommendBidPrice, RtbCampaignPlanType, RtbOptimize } from 'core/rtbCampaign/RtbCampaign';
import { DefaultRtbCampaignBasicFormModel, RtbCampaignBasicFormModel, RtbCampaignBasicFormModelConstructorParams } from './RtbCampaignBasicFormModel';
import { OPERATE } from 'enum/Operate';
import { L1ObjectObjective } from 'core/l1Object/L1Object';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { omitBy, isEmpty, get, xor, defaultTo } from 'lodash';
import { DefaultPCHomeManager, PCHomeManager } from 'core/pchome/PCHomeManager';

const pchomeManager: PCHomeManager = new DefaultPCHomeManager();

export interface KeywordFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractKeywordFormModel extends DefaultRtbCampaignBasicFormModel implements KeywordFormModel {

  keywords: string[];

  constructor (
    mode: 'create' | 'edit',
    ...args: RtbCampaignBasicFormModelConstructorParams
  ) {
    super(mode, ...args);
    this.defaultDeliverType = DeliverType.ACCELERATED;
    this.keywords = [];
  }

  get campaignAdType (): AdType {
    return AdType.KEYWORD;
  }

  get needSetupAgeGender (): boolean {
    return false;
  }

  get showDeliverType (): boolean {
    return +this.modelDailyBudgetType === DailyBudgetPlan.DAILY;
  }

  get showTags (): boolean {
    return false;
  }

  get showOptimizeSection (): boolean {
    return false;
  }

  get limitationOperates (): {
    need: string[],
    notNeed?: string[],
    other?: string[]
  } {
    return {
      need: [OPERATE.INCLUDE],
      notNeed: undefined,
      other: undefined
    };
  }

  async fetchRecommendBidPrice (limitationValue: any): Promise<void> {
    this.updateState(true);
    const includeTA = get(limitationValue, 'include', []);
    const searchKeywordsTA = includeTA.find((ta: any) => ta.type === 'searchKeywords');
    const searchKeywords = searchKeywordsTA ? searchKeywordsTA.value.map((keyword: any) => keyword.value) : [];
    // check if this.keywords is same as searchKeywords, if same, no need to fetch again
    if (xor(this.keywords, searchKeywords).length === 0) {
      this.updateState(false);
      return;
    }
    const recommendBidPrice: RecommendBidPrice = await pchomeManager.getRecommendBidPrice(searchKeywords);
    this.recommendBidPrice = recommendBidPrice;
    this.keywords = searchKeywords;
    this.updateState(false);
  }

  getBidPriceRange (optimize: RtbOptimize): {
    max?: number,
    min: number,
    recommend?: {
      min: number,
      max: number
    }
  } {
    const recommendBidPrice: RecommendBidPrice | undefined = this.state.recommendBidPrice;
    const recommend = !isEmpty(recommendBidPrice) ? {
      min: defaultTo(recommendBidPrice.bidFloor, 5),
      max: defaultTo(recommendBidPrice.bidCap, 20)
    } : {
      min: 5,
      max: 20
    };
    return {
      max: this.getBidCap(optimize),
      min: this.getBidFloor(optimize),
      recommend: recommend
    };
  }

  getRtbOptionsMap () {
    const optionsMap = super.getRtbOptionsMap();
    const enableFCPC = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.FIXED_CPC);
    return omitBy({
      ...optionsMap,
      [L1ObjectObjective.TRAFFIC]: {
        [RtbCampaignPlanType.RS_CPC]: [
          L2ObjectOptimizationGoal.CLICKS
        ],
        [RtbCampaignPlanType.FCPC]: enableFCPC ? [
          L2ObjectOptimizationGoal.CLICKS
        ] : undefined
      }
    }, isEmpty);
  }

  setupDefaultCampaign (campaign: any) {
    super.setupDefaultCampaign(campaign);
    campaign.dailyTargetBudget = 0;
  }
}

export class EditKeywordFormModel extends AbstractKeywordFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
}

export class CreateKeywordFormModel extends AbstractKeywordFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
    this.modelDailyBudgetType = DailyBudgetPlan.DAILY;
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
